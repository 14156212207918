import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FormsModules } from '@utils/shared-modules';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AuthService } from '@services/auth/auth.service';
import { TelInputComponent } from '@modules/shared/components/tel-input/tel-input.component';
import { LoginRequest } from '@models/users/dto/login.request';
import { UserService } from '@services/auth/user.service';
import { finalize } from 'rxjs';
import { CredentialsFormButtonToggleComponent } from '@modules/shared/components/credentials-form-button-toggle/credentials-form-button-toggle.component';
import { ActivatedRoute, Router } from '@angular/router';
import { GlobalSettingsStore } from '@services/stores/global_settings.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GlobalSettings } from '@models/users/global_settings.model';

@Component({
  selector: 'app-login-form',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModules,
    TelInputComponent,
    CredentialsFormButtonToggleComponent,
  ],
  providers: [UserService],
  templateUrl: './login-form.component.html',
  styleUrl: './login-form.component.scss',
})
export class LoginFormComponent implements OnInit {
  form?: FormGroup;
  loginIsInProgress = false;
  @Input() selectedOption: string = 'phone';
  @Input() invitedCoHostEmail?: string;

  userEmail: string = '';
  userPhone: string = '';

  showPassword = false;

  showCoHostEmailChangedHint = false;

  globalSettings?: GlobalSettings;

  // @Input() step: number = 0;

  @Output() setStepEvent = new EventEmitter<any>();

  @ViewChild('telInput') telInput?: TelInputComponent;

  constructor(
    private authService: AuthService,
    private translateService: TranslateService,
    private userService: UserService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private globalSettingsStore: GlobalSettingsStore,
  ) {
    // const snapshot = this.activatedRoute.snapshot;
    // if (snapshot.queryParamMap.has("phone")) {
    //   const userPhone = snapshot.queryParamMap.get('phone');
    //   if(userPhone && userPhone !== '') {
    //     this.userPhone = userPhone;
    //     this.router.navigate([], {
    //       relativeTo: this.activatedRoute,
    //       queryParams: { phone: null },
    //       queryParamsHandling: 'merge',
    //       replaceUrl: true,
    //     });
    //   }
    // }
    this.globalSettingsStore.globalSettings
      .pipe(takeUntilDestroyed())
      .subscribe((globalSettings) => {
        this.globalSettings = globalSettings;
      });
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      emailFormGroup: new FormGroup({
        email: new FormControl(this.invitedCoHostEmail || '', [
          Validators.required,
          Validators.email,
        ]),
        // password: new FormControl('', [Validators.required]),
      }),
      phoneFormGroup: new FormGroup({
        phone: new FormControl(this.userPhone || '', [Validators.required]),
      }),
    });

    if (this.invitedCoHostEmail && this.invitedCoHostEmail !== '') {
      this.emailFormGroup.controls['email'].valueChanges.subscribe((val) => {
        this.showCoHostEmailChangedHint = val !== this.invitedCoHostEmail;
      });
    }
  }

  get emailFormGroup(): FormGroup {
    return this.form?.get('emailFormGroup') as FormGroup;
  }

  get phoneFormGroup(): FormGroup {
    return this.form?.get('phoneFormGroup') as FormGroup;
  }

  selectOption(option: string): void {
    this.selectedOption = option;

    if (this.selectedOption === 'phone') {
      this.phoneFormGroup.enable();
      this.emailFormGroup.reset();
      this.emailFormGroup.disable();
    } else if (this.selectedOption === 'email') {
      this.emailFormGroup.enable();
      this.phoneFormGroup.controls['phone'].setValue('');
      this.phoneFormGroup.controls['phone'].updateValueAndValidity();
      this.telInput?.reset();
      this.phoneFormGroup.disable();
    }
    // this.formInit();
  }

  next(): void {
    if (this.selectedOption === 'email' && this.emailFormGroup.invalid) {
      this.emailFormGroup.markAllAsTouched();
      return;
    } else if (this.selectedOption === 'phone' && this.phoneFormGroup.invalid) {
      this.phoneFormGroup.markAllAsTouched();
      this.telInput?.markAsTouched();
      return;
    }

    const values =
      this.selectedOption === 'email'
        ? this.emailFormGroup.value
        : this.phoneFormGroup.value;

    this.loginIsInProgress = true;
    let loginReq: LoginRequest;
    let req = new Map<string, any>();
    if (this.selectedOption === 'email') {
      this.userEmail = values.email;
      loginReq = {
        email: this.userEmail,
        password: values.password,
      };
      req.set('email', this.userEmail);

      this.authService
        .userOrAccountWithEmailExists(this.userEmail)
        .pipe(finalize(() => (this.loginIsInProgress = false)))
        .subscribe({
          next: (exists) => {
            if (this.globalSettings?.registerWithoutEmail && !exists) {
              this.emailFormGroup.controls['email'].setErrors({
                custom: this.translateService.instant(
                  'APP.AUTH.ERRORS.USER_NOT_FOUND',
                ),
              });
            }

            this.setStepEvent.emit({
              // step: ++this.step,
              userEmail: this.userEmail,
              selectedOption: this.selectedOption,
              hasNoAccount: !exists,
            });
          },
          error: (err) => {
            if (err.error === 'could not load user: record not found') {
              if (this.globalSettings?.registerWithoutEmail) {
                this.emailFormGroup.controls['email'].setErrors({
                  custom: this.translateService.instant(
                    'APP.AUTH.ERRORS.USER_NOT_FOUND',
                  ),
                });
              } else {
                this.setStepEvent.emit({
                  // step: ++this.step,
                  userEmail: this.userEmail,
                  selectedOption: this.selectedOption,
                  hasNoAccount: true,
                });
              }
            }
          },
        });
    } else {
      this.userPhone = values.phone;
      loginReq = {
        phone: this.userPhone,
      };
      req.set('phone', this.userPhone);

      this.userService
        .findByEmailOrPhone(req)
        .pipe(finalize(() => (this.loginIsInProgress = false)))
        .subscribe({
          next: (user) => {
            this.setStepEvent.emit({
              // step: ++this.step,
              otpUserId: user.id,
              userPhone: this.userPhone,
              selectedOption: this.selectedOption,
              hasNoAccount: !user.id,
            });
          },
          error: (err) => {
            if (err.error === 'could not load user: record not found') {
              this.phoneFormGroup.controls['phone'].setErrors({
                custom: this.translateService.instant(
                  'APP.AUTH.ERRORS.USER_NOT_FOUND',
                ),
              });
              this.setStepEvent.emit({
                // step: ++this.step,
                userPhone: this.userPhone,
                selectedOption: this.selectedOption,
                hasNoAccount: true,
              });
            }
          },
        });
    }
  }
}
