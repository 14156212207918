import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';

@Component({
  selector: 'app-event-page-logo',
  standalone: true,
  imports: [],
  templateUrl: './event-page-logo.component.html',
  styleUrl: './event-page-logo.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EventPageLogoComponent {
  @Input() size = 22;
}
