<div class="personal-data">
  <div class="title-wrapper">
    @if(showTitle){
      <div class="title">
        {{ "APP.EVENT_PAYMENT.PERSONAL_DATA" | translate  }}
      </div>
    }

    @if (!loggedUser || loggedUser.id === 0) {
      <div class="description">
        <span class="highlighted" (click)="login()">{{
          "APP.EVENT_PAYMENT.LOG_IN" | translate
        }}</span>
        {{ "APP.EVENT_PAYMENT.TO_SPEED_THINGS_UP" | translate }}
      </div>
    }
  </div>
  <!-- <app-credentials-form-button-toggle
    [selectedOption]="selectedOption"
    (selectedOptionEvent)="selectOption($event)"
  >
  </app-credentials-form-button-toggle> -->
  @if (form) {
    <form [formGroup]="form" class="form">
      <mat-form-field [appearance]='appearance' class="flex-input w-100">
        <mat-label>{{ "APP.NAME" | translate }} *</mat-label>
        <input
          type="text"
          matInput
          formControlName="name"
          placeholder=""
          [readonly]="!!loggedUser?.id"
        />
      </mat-form-field>
      @if (selectedOption !== "phone") {
        <mat-form-field [appearance]=appearance class="flex-input email-input w-100">
          <mat-label>{{ "APP.EMAIL" | translate }} *</mat-label>
          <input
            type="text"
            matInput
            formControlName="email"
            [readonly]="
              loggedUser && !!loggedUser.id && loggedUser.email !== ''
            "
          />
        </mat-form-field>
      }
      @if (selectedOption === "phone") {
        <mat-form-field class="flex-input phone-form w-100" appearance="fill">
          <app-tel-input formControlName="phone"></app-tel-input>
        </mat-form-field>
      }

      <!-- <div class='password-hint'>
        {{ 'APP.EVENT_PAYMENT.SET_UP_PASSWORD' | translate }}
      </div>
      <mat-form-field appearance="fill" class="flex-input w-100">
        <mat-label>{{ "APP.AUTH.PASSWORD" | translate }} *</mat-label>
        <input
          type="text"
          matInput
          formControlName="password"
          placeholder=""
        />
        <mat-hint class='form-hint w-100'>{{ 'APP.EVENT_PAYMENT.REQUIRED_FIELD' | translate }}</mat-hint>
      </mat-form-field> -->
      <div class="notification-field">
        <label class="checkbox-container" [class.radio-button]='appearance === "outline"'>
          <input
            class="checkbox-input"
            type="checkbox"
            formControlName="agreeToTerms"
          />
          <div class="checkbox-border">
            @if (form.controls["agreeToTerms"].value) {
              <div class="checkbox-filling"></div>
            }
          </div>
          <ng-content></ng-content>
        </label>
        <div>{{ "APP.EVENT_PAYMENT.SEND_NOTIFICATIONS" | translate }}</div>
      </div>
    </form>
  }
</div>
