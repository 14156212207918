import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output, signal } from '@angular/core';
import { EventAttendeeTicketOrder } from '@models/events/event-attendee-ticket-order.model';
import { EventAttendeeTicket } from '@models/events/event-attendee-ticket.model';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { TicketVoucherCodeResponse } from '@models/tickets/dto/ticket-voucher-code.response';
import { TranslateModule } from '@ngx-translate/core';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import { NumberUtils } from '@utils/number-utils';

@Component({
  selector: 'app-order-overview',
  standalone: true,
  imports: [CommonModule, TranslateModule],
  templateUrl: './order-overview.component.html',
  styleUrl: './order-overview.component.scss',
})
export class OrderOverviewComponent {
  @Input() ticketOrder?: EventAttendeeTicketOrder;
  @Input() event?: Event;
  @Input() mainImage?: string;
  @Input() isValid = false;
  @Input() showImage: boolean = true;
  @Input() showOrder: boolean = true;
  @Input() showTitle: boolean = true;
  @Input() disableMobileView: boolean = false;
  @Input() paying = signal(false);
  @Input() voucher?: TicketVoucherCodeResponse;
  @Input() squaredStyle?: boolean = false;
  @Output() pay = new EventEmitter<void>();
  isDesktop?: boolean;
  isOrderOpen?: boolean = false;

  ticketQuantityMap?: {
    ticket: EventAttendeeTicket;
    quantity: number;
  }[];

  constructor(private screenWidthService: ScreenWidthService) {
    this.screenWidthService.isDesktop().subscribe((isDesktop) => {
      this.isDesktop = isDesktop;
    });
  }

  ngOnInit() {
    this.ticketQuantityMap = this.ticketOrder?.getTicketQuantityMap();
  }

  onPay() {
    this.pay.emit();
  }

  toggleIsOrderOpen() {
    this.isOrderOpen = !this.isOrderOpen;
  }

  protected readonly NumberUtils = NumberUtils;
  protected readonly EventType = EventType;
}
