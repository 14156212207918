import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { AppRoutes } from 'app/routes';
import { LoginFormComponent } from '@modules/auth/components/login-form/login-form.component';
import { SsoButtonsComponent } from '@modules/auth/components/sso-buttons/sso-buttons.component';
import { HCaptchaInfoComponent } from '@modules/shared/components/h-captcha-info/h-captcha-info.component';
import { VerifyPhoneDialogComponent } from '@modules/events/dialogs/verify-phone-dialog/verify-phone-dialog.component';
import { LoginRequest } from '@models/users/dto/login.request';
import { AuthService } from '@services/auth/auth.service';
import { NotificationService } from '@services/shared/notification.service';
import { LoginStep2Component } from '@modules/auth/components/login-step2/login-step2.component';
import { BackService } from '@services/shared/back.service';
import { takeUntil } from 'rxjs/internal/operators/takeUntil';
import { Subject } from 'rxjs';
import { NewUserCreationRequest } from '@models/users/dto/new-user-creation.request';
import { UserService } from '@services/auth/user.service';
import { GlobalSettings } from '@models/users/global_settings.model';
import { GlobalSettingsStore } from '@services/stores/global_settings.store';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { environment as env } from '@environments/environment';

@Component({
  selector: 'app-dialog-login',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    LoginFormComponent,
    SsoButtonsComponent,
    HCaptchaInfoComponent,
    VerifyPhoneDialogComponent,
    LoginStep2Component,
  ],
  providers: [NotificationService, TranslateService, UserService],
  templateUrl: './dialog-login.component.html',
  styleUrl: './dialog-login.component.scss',
})
export class DialogLoginComponent implements OnInit, OnDestroy {
  step: number = 0;
  @Input() userPhone?: string = '';
  userEmail: string = '';
  @Input() invitedCoHostEmail?: string;
  @Input() selectedOption: string = 'phone';
  isWrongCode: boolean = false;
  userId?: number;
  password?: string;

  globalSettings?: GlobalSettings;

  @Output() success = new EventEmitter<void>();
  @Output() toSignUp = new EventEmitter<void>();
  @Output() hasNoAccount = new EventEmitter<any>();

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(
    private router: Router,
    private authService: AuthService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private backService: BackService,
    private userService: UserService,
    private globalSettingsStore: GlobalSettingsStore,
  ) {
    this.globalSettingsStore.globalSettings
      .pipe(takeUntilDestroyed())
      .subscribe((globalSettings) => {
        this.globalSettings = globalSettings;
      });
  }

  ngOnInit(): void {
    this.backService.backButtonClick$
      .pipe(takeUntil(this._onDestroy))
      .subscribe((clicked) => {
        if (clicked && this.step === 1) {
          this.step--;
          this.backService.showBackButton(false);
        }
      });

    if (this.userPhone) {
      const createOrFindUserReq: NewUserCreationRequest = {
        phone: this.userPhone,
      };
      this.userService.findUser(createOrFindUserReq).subscribe({
        next: (userId: number) => {
          this.userId = userId;
          this.step++;
          this.backService.showBackButton(true);
        },
        error: () => {},
      });
    }
  }

  goToForgotPassword(): void {
    const url = this.router.serializeUrl(
      this.router.createUrlTree(['/', AppRoutes.Root.forgotPassword]),
    );
    window.open(url, '_blank');
  }

  goToSignUp(): void {
    this.toSignUp.emit();
  }

  setStep(event: any) {
    this.selectedOption = event.selectedOption;
    const hasNoAccount = event.hasNoAccount;

    if (
      event.selectedOption &&
      event.selectedOption === 'email' &&
      !this.globalSettings?.registerWithoutEmail
    ) {
      this.userEmail = event.userEmail;

      if (hasNoAccount) {
        this.hasNoAccount.emit({ email: this.userEmail });
      }
    } else if (event.selectedOption && event.selectedOption === 'phone') {
      this.userPhone = event.userPhone;

      if (hasNoAccount) {
        this.hasNoAccount.emit({ phone: this.userPhone });
      }
    }

    if (!hasNoAccount) {
      this.step++;
      this.backService.showBackButton(true);
    }
  }

  authLoginEvent(event: any) {
    if (event.logUser) {
      this.authLogin(event.loginRequest);
    }
  }

  changePhone() {
    this.step = 0;
    this.backService.showBackButton(false);
  }
  authLogin(loginReq: LoginRequest) {
    if (loginReq.phone && loginReq.otp) {
      this.authService.loginWithOtp(
        loginReq,
        false,
        () => {
          this.success.emit();
        },
        (denied: any) => {
          this.isWrongCode = true;
          this.notificationService.error(
            this.translateService.instant('APP.AUTH.ERRORS.INVALID_CODE'),
          );
        },
      );
    }
  }

  onSuccess() {
    this.success.emit();
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }

  protected readonly env = env;
}
