@if(isDesktop || !isGeneric){
<div class="payment-wrapper" [class.advanced-event]='event?.type === EventType.ADVANCED_ONE_COLUMN'>
  <div class="payment-body">
    <div class="customer-info">
        <app-personal-data [form]="form" [loggedUser]="loggedUser" [appearance]='getAppearance()'/>
        @if(isGeneric){
          <mat-accordion class="wrapper-expansion partly-clickable" multi>
            <mat-expansion-panel  [expanded]="setBillingAddressOpenState()" (opened)="setBillingAddressOpenState.set(true)" (closed)="setBillingAddressOpenState.set(false)">
              <mat-expansion-panel-header>
                <mat-panel-title><span class='panel-title'>{{ "APP.VOUCHERS.ADD_BILLING_ADDRESS" | translate }}</span></mat-panel-title>
              </mat-expansion-panel-header>
              <app-tax-invoice-details [form]="billingAddressForm" [appearance]='getAppearance()' [showTitle]=false [isGeneric]=true class='w-100'/>
            </mat-expansion-panel>
          </mat-accordion>
        }@else{
          <app-tax-invoice-details [form]="billingAddressForm" [appearance]='getAppearance()' class='w-100'/>
        }
        @if (elementsOptions && elementsOptions.clientSecret && canPay) {
          @if(ticketOrder && ticketOrder.totalPrice > 0){
            <div class="pay-with-cont">
              <div class="title-wrapper">
                <div class="title">
                  {{ "APP.EVENT_PAYMENT.PAY_WITH" | translate }}
                </div>
              </div>
              <ngx-stripe-elements
                [stripe]="stripe"
                [elementsOptions]="elementsOptions"
                class="stripe-elements"
              >
                @if(isGeneric){
                  <ngx-stripe-card [options]="cardOptions" (change)="stripeElementsChange($event)" />
                }@else{
                  <ngx-stripe-payment
                    [options]="paymentElementOptions"
                    (change)="stripeElementsChange($event)"
                  />
                }
              </ngx-stripe-elements>
            </div>
            @if(isGeneric){
              <button
                class="btn btn-primary order-btn"
                (click)="pay()"
                [class.spinner]="paying()"
                [disabled]="!isStripeElementValid"
              >
                {{ "APP.EVENT_PAYMENT.ORDER" | translate }}
              </button>
            }
          }@else{
            @if(isGeneric){
              <button
                class="btn btn-primary order-btn"
                (click)="pay()"
                [class.spinner]="paying()"
                [disabled]="!isStripeElementValid"
              >
                {{ "APP.REGISTER" | translate }}
              </button>
            }
          }
        }


    </div>


    @if(!isGeneric){
      <app-order-overview
        [voucher]='voucher'
        [ticketOrder]="ticketOrder"
        [mainImage]="mainImage"
        [squaredStyle]='event?.type === EventType.ADVANCED_ONE_COLUMN'
        [isValid]="
          isStripeElementValid && form.valid && !!billingAddressForm?.valid
        "
        [paying]="paying"
        [event]='event'
        (pay)="pay()"
      />
    }
  </div>
</div>
}
@if(!isDesktop && isGeneric){
  <mat-accordion class="wrapper-expansion mobile-expansion" multi>
    <mat-expansion-panel [expanded]="setPersonalDataOpenState()" (opened)="setPersonalDataOpenState.set(true)" (closed)="setPersonalDataOpenState.set(false)">
      <mat-expansion-panel-header>
        <mat-panel-title><span class='panel-title'>{{ "APP.EVENT_PAYMENT.PERSONAL_DATA" | translate }}</span></mat-panel-title>
      </mat-expansion-panel-header>
      <div class='generic-mobile-view'>
        <app-personal-data [form]="form" [loggedUser]="loggedUser" [showTitle]=false [appearance]='getAppearance()'/>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
  @if(setPersonalDataOpenState()){
    <mat-accordion class="wrapper-expansion mobile-expansion partly-clickable" multi>
      <mat-expansion-panel [expanded]="setBillingAddressOpenState()" (opened)="setBillingAddressOpenState.set(true)" (closed)="setBillingAddressOpenState.set(false)">
        <mat-expansion-panel-header>
          <mat-panel-title><span class='panel-title'>{{ "APP.VOUCHERS.ADD_BILLING_ADDRESS" | translate }}</span></mat-panel-title>
        </mat-expansion-panel-header>
        <div class='generic-mobile-view py-0'>
          <app-tax-invoice-details [form]="billingAddressForm" [appearance]='getAppearance()' [showTitle]=false [isGeneric]='true' class='w-100'/>
        </div>
      </mat-expansion-panel>
    </mat-accordion>
  }
    @if (elementsOptions && elementsOptions.clientSecret && canPay) {
      <mat-accordion class="wrapper-expansion mobile-expansion" multi>
        <mat-expansion-panel [expanded]="setPaymentCardOpenState()" (opened)="setPaymentCardOpenState.set(true)" (closed)="setPaymentCardOpenState.set(false)">
          <mat-expansion-panel-header>
            <mat-panel-title><span class='panel-title'>{{ "APP.TICKETS_EDIT.PAYMENT" | translate }}</span></mat-panel-title>
          </mat-expansion-panel-header>
          <div class='payment-info'>
            <div class="pay-with-cont">
              <ngx-stripe-elements
                [stripe]="stripe"
                [elementsOptions]="elementsOptions"
                class="stripe-elements"
              >
                <ngx-stripe-card [options]="cardOptions" (change)="stripeElementsChange($event)"/>
              </ngx-stripe-elements>
            </div>
            @if(isGeneric){
              <button
                class="btn btn-primary order-btn"
                (click)="pay()"
                [disabled]="!isStripeElementValid"
                [class.spinner]="paying()"
              >
                {{ "APP.EVENT_PAYMENT.ORDER" | translate }}
              </button>
            }
          </div>
        </mat-expansion-panel>
      </mat-accordion>
    }
}
