import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { DialogLoginComponent } from '@modules/auth/components/dialog-login/dialog-login.component';
import { DialogSignUpComponent } from '@modules/auth/components/dialog-sign-up/dialog-sign-up.component';
import { BackService } from '@services/shared/back.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { BackButtonComponent } from '@modules/shared/components/back-button/back-button.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-auth',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    DialogLoginComponent,
    DialogSignUpComponent,
    BackButtonComponent,
  ],
  templateUrl: './auth.dialog.html',
  styleUrl: './auth.dialog.scss',
})
export class AuthDialog {
  flow?: string;

  isLogin = false;
  isTicketing = false;

  selectedOption: string = 'phone';
  email?: string;
  phone?: string;
  hasNoAccount = false;

  showBackButton = false;

  invitedCoHostEmail?: string;

  constructor(
    private backService: BackService,
    public dialogRef: MatDialogRef<AuthDialog>,
    @Inject(MAT_DIALOG_DATA) public data: any,
  ) {
    if (data) {
      this.flow = data.flow;
      this.isLogin = data.isLogin;
      this.isTicketing = data.isTicketing;

      if (data.invitedCoHostEmail) {
        this.invitedCoHostEmail = data.invitedCoHostEmail;
        this.phone = undefined;
        this.selectedOption = 'email';
      }
    }

    this.backService.showBackButton$
      .pipe(takeUntilDestroyed())
      .subscribe((showBackButton) => {
        this.showBackButton = showBackButton;
      });
  }

  goToLogin(event?: any) {
    if (event?.phone) {
      this.phone = event.phone;
    } else if (event?.email) {
      this.email = event.email;
    }
    this.isLogin = true;
  }

  goToSignUp() {
    this.isLogin = false;
  }

  onHasNoAccount(data: any) {
    if (data) {
      if (data.email) {
        this.email = data.email;
        this.phone = undefined;
        this.selectedOption = 'email';
      } else if (data.phone) {
        this.phone = data.phone;
        this.email = undefined;
        this.selectedOption = 'phone';
      }
      this.hasNoAccount = true;
    }
    this.goToSignUp();
  }

  onSuccess() {
    this.dialogRef.close({ success: true });
  }

  close() {
    this.dialogRef.close();
  }
}
