import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { SignUpStep0Component } from '@modules/sign-up/components/sign-up-step0/sign-up-step0.component';
import { SignUpStep1Component } from '@modules/sign-up/components/sign-up-step1/sign-up-step1.component';
import { AuthService } from '@services/auth/auth.service';
import { RouterModule } from '@angular/router';
import { EnterPhoneRes } from '@models/sign-up/enter-phone-res.model';
import { VerifyEmailStepComponent } from '@modules/sign-up/components/verify-email-step/verify-email-step.component';
import { SetPhoneStepComponent } from '@modules/sign-up/components/set-phone-step/set-phone-step.component';
import { VerifyPhoneStepComponent } from '@modules/sign-up/components/verify-phone-step/verify-phone-step.component';
import { RegisterSuccess } from '@models/sign-up/register-success.model';
import { BackService } from '@services/shared/back.service';
import { Subject } from 'rxjs';
import { finalize, takeUntil } from 'rxjs/operators';
import { LoginRequest } from '@models/users/dto/login.request';
import { UserService } from '@services/auth/user.service';
import { NotificationService } from '@services/shared/notification.service';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { SignUpReferralStepComponent } from '@modules/sign-up/components/sign-up-referral-step/sign-up-referral-step.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GlobalSettingsStore } from '@services/stores/global_settings.store';
import { GlobalSettings } from '@models/users/global_settings.model';

@Component({
  selector: 'app-dialog-sign-up',
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    SignUpStep0Component,
    SignUpStep1Component,
    SignUpReferralStepComponent,
    VerifyEmailStepComponent,
    SetPhoneStepComponent,
    VerifyPhoneStepComponent,
  ],
  providers: [UserService, NotificationService],
  templateUrl: './dialog-sign-up.component.html',
  styleUrl: './dialog-sign-up.component.scss',
})
export class DialogSignUpComponent implements OnInit, OnDestroy {
  @Input() flow?: string;

  step: number = 0;
  @Input() email?: string;
  password?: string;
  @Input() phone?: string;
  userId?: number;
  @Input() selectedOption: string = 'phone';
  isWrongCode: boolean = false;

  @Input() hasNoAccount = false;

  @Input() invitedCoHostEmail?: string;

  formValue?: any;
  referralCode?: string;

  otpCode?: string;

  signUpIsInProgress = false;

  globalSettings?: GlobalSettings;

  @Output() success = new EventEmitter<void>();
  @Output() toLogin = new EventEmitter<any>();

  /** Subject that emits when the component has been destroyed. */
  private _onDestroy = new Subject<void>();

  constructor(
    private authService: AuthService,
    private userService: UserService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private backService: BackService,
    private globalSettingsStore: GlobalSettingsStore,
  ) {
    this.globalSettingsStore.globalSettings
      .pipe(takeUntilDestroyed())
      .subscribe((globalSettings) => {
        this.globalSettings = globalSettings;
      });
  }

  ngOnInit(): void {
    this.backService.backButtonClick$
      .pipe(takeUntil(this._onDestroy))
      .subscribe((clicked) => {
        if (clicked) {
          if (this.step === 1 || this.step === 4) {
            this.step--;
            this.backService.showBackButton(false);
          } else if (this.step === 2) {
            this.step--;
          }
        }
      });
  }

  selectOption(option: string): void {
    this.selectedOption = option;
    if (this.hasNoAccount) {
      this.hasNoAccount = false;
    }
    this.phone = undefined;
    this.email = undefined;
  }

  onPhoneEntered(phone: string) {
    this.phone = phone;
    this.step++;
    this.backService.showBackButton(true);
  }

  onEmailEntered(email: string) {
    this.email = email;
    this.step++;
    this.backService.showBackButton(true);
  }

  onRegisterSuccess(res: RegisterSuccess) {
    this.userId = res.userId;
    this.password = res.password;

    // if ((!this.referralCode || this.referralCode === '') && this.step === 1) {
    //   this.step = 3;
    // } else {
    //   this.step++;
    // }
    this.step++;

    this.backService.showBackButton(false);
  }

  signUpFromForm(value: any): void {
    this.formValue = value;
    this.signUp();
    // this.step++;
  }

  signUpWReferral(referralCode: string) {
    this.referralCode = referralCode;
    this.loginAndEmit(referralCode);
  }

  signUp() {
    if (!this.formValue || this.signUpIsInProgress) {
      return;
    }

    this.signUpIsInProgress = true;

    if (this.selectedOption === 'email') {
      this.userService
        .register(
          this.email,
          this.formValue.password,
          this.formValue.name,
          // this.referralCode,
        )
        .pipe(finalize(() => (this.signUpIsInProgress = false)))
        .subscribe({
          next: (res) => {
            this.onRegisterSuccess({
              userId: res.id,
              password: this.formValue.password,
            });
          },
          error: (err) => {
            this.handleSignUpError(err);
          },
        });
    } else if (this.selectedOption === 'phone') {
      this.userService
        .registerWithPhone(
          this.phone,
          this.formValue.name,
          // this.referralCode
        )
        .pipe(finalize(() => (this.signUpIsInProgress = false)))
        .subscribe({
          next: (res) => {
            this.onRegisterSuccess({
              userId: res.id,
            });
          },
          error: (err) => {
            this.handleSignUpError(err);
          },
        });
    }
  }

  handleSignUpError(err: any): void {
    if (err && err.error?.startsWith('bad request - invalid referral code')) {
      this.notificationService.error(
        this.translateService.instant('APP.AUTH.ERRORS.INVALID_REFERRAL_CODE'),
        this.translateService.instant(
          'APP.AUTH.ERRORS.COULD_NOT_SIGN_UP_TITLE',
        ),
      );

      this.isWrongCode = true;

      if (this.step === 1) {
        this.step = 2;
      }
    } else {
      this.notificationService.error(
        this.translateService.instant('APP.AUTH.ERRORS.COULD_NOT_SIGN_UP'),
      );
    }
  }

  changePhone() {
    this.selectedOption = 'phone';
    this.step = 0;
  }
  onVerifyEmailSuccess() {
    // this.loginAndEmit();
    this.step++;
  }

  onEnterPhoneRes(res: EnterPhoneRes) {
    if (res.skipped) {
      this.loginAndEmit();
    } else {
      this.phone = res.phone;
      this.step++;
    }
  }

  onVerifyPhoneSuccess(code: string) {
    // this.loginAndEmit(code);
    this.otpCode = code;
    this.step++;
  }

  private loginAndEmit(code?: string) {
    if (this.email && this.password && this.password !== '') {
      const loginReq: LoginRequest = {
        email: this.email,
        password: this.password,
        referralCode: code,
      };

      this.authService.login(
        loginReq,
        false,
        () => {
          this.success.emit();
        },
        () => {},
      );
    } else if (this.phone) {
      const loginReq: LoginRequest = {
        phone: this.phone,
        otp: this.otpCode,
        referralCode: code,
      };

      this.authService.loginWithOtp(
        loginReq,
        false,
        () => {
          this.success.emit();
        },
        () => {
          this.isWrongCode = true;
        },
      );
    }
  }

  goToLogin(event?: any) {
    this.toLogin.emit(event);
  }

  ngOnDestroy(): void {
    this._onDestroy.next();
    this._onDestroy.complete();
  }
}
