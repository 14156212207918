<svg
  id="Ebene_2"
  data-name="Ebene 2"
  xmlns="http://www.w3.org/2000/svg"
  xmlns:xlink="http://www.w3.org/1999/xlink"
  viewBox="0 0 434.9 434.9"
  [attr.width]="size"
  [attr.height]="size"
>
  <defs>
    <style>
      .cls-1 {
        clip-path: url(#clippath);
      }

      .cls-2,
      .cls-3 {
        fill: none;
      }

      .cls-2,
      .cls-3,
      .cls-4 {
        stroke-width: 0px;
      }

      .cls-5 {
        clip-path: url(#clippath-1);
      }

      .cls-6 {
        clip-path: url(#clippath-3);
      }

      .cls-7 {
        clip-path: url(#clippath-2);
      }

      .cls-3 {
        clip-rule: evenodd;
      }

      .cls-4 {
        fill: var(--event-theme-body-color);
      }
    </style>
    <clipPath id="clippath">
      <path
        class="cls-3"
        d="m407.01,65.58C376.1,20.84,315.87,0,217.45,0S58.8,20.84,27.89,65.58C0,105.96,0,160.11,0,217.45s0,111.49,27.89,151.87c30.91,44.74,91.14,65.58,189.56,65.58s158.65-20.84,189.56-65.58c27.89-40.38,27.89-94.53,27.89-151.87s0-111.49-27.89-151.87m-22.41,288.26c-11.88,17.2-29.51,29.83-53.92,38.61-28.45,10.24-65.48,15.21-113.23,15.21s-84.78-4.98-113.22-15.21c-24.4-8.78-42.04-21.41-53.92-38.61-23.06-33.39-23.06-81.12-23.06-136.39s0-103,23.06-136.39c11.88-17.2,29.52-29.83,53.92-38.61,28.45-10.24,65.48-15.21,113.22-15.21s84.78,4.97,113.23,15.21c24.4,8.78,42.04,21.41,53.92,38.61,23.07,33.39,23.07,81.12,23.07,136.39s0,102.99-23.07,136.39"
      />
    </clipPath>
    <clipPath id="clippath-1">
      <rect class="cls-2" x="0" y="0" width="434.9" height="434.9" />
    </clipPath>
    <clipPath id="clippath-2">
      <path
        class="cls-3"
        d="m142.02,146.78c-23.31,0-42.22,7.39-54.68,21.38-10.75,12.06-16.67,29.05-16.67,47.82s5.75,34.82,16.63,46.34c12.77,13.51,31.95,20.37,56.99,20.37,23.38,0,43.46-6.02,61.31-18.4l-14.67-25.16c-15.79,10.43-28.05,14.53-43.11,14.53-23.15,0-36.44-9.96-39.51-29.59l-.4-2.55h104.1c-1.27-24.7-7.51-43.41-18.57-55.64-11.61-12.84-28.43-19.08-51.42-19.08Zm32.76,60.68h-66.84l.36-2.53c2.71-18.78,14.86-29.12,34.22-29.12s30.56,9.76,34.45,29l.53,2.65h-2.73Z"
      />
    </clipPath>
    <clipPath id="clippath-3">
      <path
        class="cls-3"
        d="m350.63,266.09c12.45-12.61,19.03-31.62,19.03-54.98,0-40.88-20.08-64.33-55.08-64.33-25.75,0-45.04,12.26-55.8,35.46l-4.4,9.5v-43.14l-36.94,4.9v188.98h36.94v-93.11l4.37,8.53c8.55,16.7,24.8,25.53,47.01,25.53,18.15,0,33.66-6,44.87-17.35Zm-96.24-68.21l.74-.66c3.39-3.02,21.49-18.1,45.18-18.1,25.94,0,31.38,18.62,31.38,34.24,0,24.82-12.8,38.49-36.05,38.49-18.82,0-41.25-9.2-41.25-53v-.97Z"
      />
    </clipPath>
  </defs>
  <g id="Ebene_1-2" data-name="Ebene 1">
    <g>
      <g class="cls-1">
        <g class="cls-5">
          <rect
            class="cls-4"
            x="-5.44"
            y="-5.44"
            width="445.77"
            height="445.77"
          />
        </g>
      </g>
      <g class="cls-7">
        <rect
          class="cls-4"
          x="65.23"
          y="141.34"
          width="152.21"
          height="146.78"
        />
      </g>
      <g class="cls-6">
        <rect
          class="cls-4"
          x="212.01"
          y="141.34"
          width="163.09"
          height="206.58"
        />
      </g>
    </g>
  </g>
</svg>
