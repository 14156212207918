import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '@services/auth/auth.service';
import { TranslateModule } from '@ngx-translate/core';
import { finalize } from 'rxjs';
import { FormsModules } from '@utils/shared-modules';
import { TelInputComponent } from '@modules/shared/components/tel-input/tel-input.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { AppRoutes } from '../../../../routes';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sign-up-phone-form',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModules,
    TelInputComponent,
    MatCheckboxModule,
  ],
  templateUrl: './sign-up-phone-form.component.html',
  styleUrl: './sign-up-phone-form.component.scss',
})
export class SignUpPhoneFormComponent implements OnChanges {
  form: FormGroup;
  inProgress = false;

  @Input() phone?: string;
  @Input() isAuthDialog: boolean = false;

  @Output() phoneEntered: EventEmitter<string> = new EventEmitter();
  @Output() toLogin: EventEmitter<any> = new EventEmitter();

  @ViewChild('telInput') telInput?: TelInputComponent;

  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
    this.form = new FormGroup({
      phone: new FormControl(this.phone || '', [Validators.required]),
      acceptedPrivacyPolicy: new FormControl(false, Validators.requiredTrue),
      acceptedTerms: new FormControl(false, Validators.requiredTrue),
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.controls['phone'].setValue(this.phone);
  }

  signUp(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.telInput?.markAsTouched();
      return;
    }

    this.inProgress = true;

    const phone = this.form.value.phone;

    this.authService
      .userOrAccountWithPhoneExists(phone)
      .pipe(finalize(() => (this.inProgress = false)))
      .subscribe({
        next: (exists: boolean) => {
          if (!exists) {
            this.phoneEntered.emit(phone);
          } else {
            this.isAuthDialog
              ? this.toLogin.emit({ phone: phone })
              : this.router.navigate(['/', AppRoutes.Root.login], {
                  queryParams: { phone: phone },
                });

            // this.form.controls['phone'].setErrors({
            //   custom: this.translateService.instant(
            //     'APP.AUTH.ERRORS.PHONE_EXISTS',
            //   ),
            // });
          }
        },
      });
  }
}
