<div class="auth-dialog event-dialog-content" mat-dialog-content>
  @if (showBackButton) {
    <app-back-button class="go-back-button"></app-back-button>
  }
  <div (click)="close()" class="close-button">
    <i class="fal fa-times-circle"></i>
  </div>
  @if (isLogin) {
    <app-dialog-login
      [selectedOption]="selectedOption"
      [userPhone]="phone"
      [invitedCoHostEmail]="invitedCoHostEmail"
      (toSignUp)="goToSignUp()"
      (hasNoAccount)="onHasNoAccount($event)"
      (success)="onSuccess()"
    ></app-dialog-login>
  } @else {
    <app-dialog-sign-up
      [flow]="flow"
      [selectedOption]="selectedOption"
      [hasNoAccount]="hasNoAccount"
      [email]="email"
      [phone]="phone"
      [invitedCoHostEmail]="invitedCoHostEmail"
      (toLogin)="goToLogin($event)"
      (success)="onSuccess()"
    ></app-dialog-sign-up>
  }
  @if (isTicketing) {
    <div class="text-btn" (click)="close()">
      {{ "APP.TICKETING.CONTINUE_AS_GUEST" | translate }}
    </div>
  }
</div>
