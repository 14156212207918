import { CommonModule } from '@angular/common';
import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  signal,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldAppearance } from '@angular/material/form-field';
import { RouterLink } from '@angular/router';
import { ConfirmEventTicketOrderRequest } from '@models/events/dto/confirm-event-ticket-order.request';
import { CreateEventTicketOrderSessionRequest } from '@models/events/dto/create-event-ticket-order-session.request';
import { EventAttendeeTicketOrder } from '@models/events/event-attendee-ticket-order.model';
import { EventType } from '@models/events/event-type.enum';
import { Event } from '@models/events/event.model';
import { BillingAddressType } from '@models/payments/billing-address-type.enum';
import { BillingAddress } from '@models/payments/billing-address.model';
import { TicketVoucherCodeResponse } from '@models/tickets/dto/ticket-voucher-code.response';
import { User } from '@models/users/user.model';
import { OrderOverviewComponent } from '@modules/events/components/event-payment/components/order-overview/order-overview.component';
import { PaymentTypeSelectionComponent } from '@modules/events/components/event-payment/components/payment-type-selection/payment-type-selection.component';
import { PersonalDataComponent } from '@modules/events/components/event-payment/components/personal-data/personal-data.component';
import { TaxInvoiceDetailsComponent } from '@modules/events/components/event-payment/components/tax-invoice-details/tax-invoice-details.component';
import { CredentialsFormButtonToggleComponent } from '@modules/shared/components/credentials-form-button-toggle/credentials-form-button-toggle.component';
import { EventPageLogoComponent } from '@modules/shared/components/event-page-logo/event-page-logo.component';
import { TelInputComponent } from '@modules/shared/components/tel-input/tel-input.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { EventService } from '@services/events/event.service';
import { NotificationService } from '@services/shared/notification.service';
import { ScreenWidthService } from '@services/shared/screen-width.service';
import {
  StripeCardElementOptions,
  StripeElementLocale,
  StripeElementsOptions,
  StripePaymentElementChangeEvent,
  StripePaymentElementOptions,
} from '@stripe/stripe-js';
import { Helpers } from '@utils/helpers';
import { NumberUtils } from '@utils/number-utils';
import { FormsModules } from '@utils/shared-modules';
import {
  injectStripe,
  StripeCardComponent,
  StripeElementsDirective,
  StripeFactoryService,
  StripePaymentElementComponent,
} from 'ngx-stripe';
import { CamelCasePipe } from '../../../../pipes/camel-case.pipe';
import { environment as env } from '@environments/environment';

@Component({
  selector: 'app-event-payment',
  standalone: true,
  imports: [
    CommonModule,
    FormsModules,
    TranslateModule,
    CredentialsFormButtonToggleComponent,
    TelInputComponent,
    PersonalDataComponent,
    TaxInvoiceDetailsComponent,
    PaymentTypeSelectionComponent,
    OrderOverviewComponent,
    StripePaymentElementComponent,
    StripeElementsDirective,
    EventPageLogoComponent,
    RouterLink,
    CamelCasePipe,
    MatExpansionModule,
    StripeCardComponent,
  ],
  providers: [EventService, NotificationService],
  templateUrl: './event-payment.component.html',
  styleUrl: './event-payment.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class EventPaymentComponent implements OnInit {
  stripe = injectStripe();

  selectedOption: string = 'phone';
  form: FormGroup;
  billingAddressForm?: FormGroup;
  @Input() event?: Event;
  @Input() isGeneric?: boolean = false;
  @Input() updatedSessionRequest?: CreateEventTicketOrderSessionRequest;
  @Input() canPay: boolean = true;
  @Output() nextStep = new EventEmitter();
  isDesktop: boolean = false;
  readonly setPersonalDataOpenState = signal(true);
  readonly setBillingAddressOpenState = signal(false);
  readonly setPaymentCardOpenState = signal(true);

  private _ticketOrder?: EventAttendeeTicketOrder;
  private _voucher?: TicketVoucherCodeResponse;
  @Input() set voucher(voucher: TicketVoucherCodeResponse | undefined) {
    this._voucher = voucher;
  }

  @Input() set ticketOrder(ticketOrder: EventAttendeeTicketOrder | undefined) {
    if (ticketOrder) {
      this._ticketOrder = ticketOrder;

      if (
        this.ticketOrder?.stripeConnectedAccountId &&
        this.ticketOrder.stripeConnectedAccountId !== ''
      ) {
        this.stripe = this.stripeFactoryService.create(env.stripePublicKey, {
          stripeAccount: this.ticketOrder?.stripeConnectedAccountId,
        });
      }

      if (this.elementsOptions.appearance) {
        this.setAppearance();
        /* this.elementsOptions.appearance.variables = {
          fontFamily: 'Montserrat',
          fontSizeBase: '1rem',
          colorPrimary: '#e60213',
          colorText: '#c5a66d',
          colorBackground: this.event?.eventTemplate?.visual?.backgroundColor,
          borderRadius: '0',
        }; */
        // const computedStyle = getComputedStyle(document.documentElement);
        // this.elementsOptions.appearance.variables = {
        //   fontFamily: computedStyle.getPropertyValue('var(--body-font-family)'),
        //   fontSizeBase: '1rem',
        //   colorPrimary: computedStyle.getPropertyValue(
        //     '--event-theme-primary-color',
        //   ),
        //   colorText: computedStyle.getPropertyValue(
        //     'var(--event-theme-body-color)',
        //   ),
        //   colorBackground: computedStyle.getPropertyValue(
        //     'var(--event-theme-background-color)',
        //   ),
        //   borderRadius: '0',
        // };
      }

      this.elementsOptions.locale = <StripeElementLocale>(
        this.translateService.currentLang
      );
      this.elementsOptions.customerSessionClientSecret =
        ticketOrder.stripeCustomerSessionClientSecret;
      this.elementsOptions.clientSecret = ticketOrder.stripePaymentIntentSecret;
    }
  }

  get voucher(): TicketVoucherCodeResponse | undefined {
    return this._voucher;
  }
  get ticketOrder(): EventAttendeeTicketOrder | undefined {
    return this._ticketOrder;
  }

  @Input() mainImage?: string;

  loggedUser?: User;

  elementsOptions: StripeElementsOptions = {
    locale: 'en',
    appearance: {
      theme: 'stripe',
      labels: 'floating',
    },
  };

  cardOptions: StripeCardElementOptions = {
    style: {
      base: {
        iconColor: '#666EE8',
        color: Helpers.getTextColorForBg(
          this.event?.eventTemplate?.visual?.backgroundColor,
        ),
        fontWeight: '400',
        fontFamily: '"Gabarito',
        fontSize: '30px',
        '::placeholder': {
          color: Helpers.getTextColorForBg(
            this.event?.eventTemplate?.visual?.backgroundColor,
          ),
        },
      },
    },
  };

  isStripeElementValid = false;

  paying = signal(false);

  @ViewChild(StripePaymentElementComponent)
  paymentElement!: StripePaymentElementComponent;

  @ViewChild(StripeCardComponent)
  cardElement!: StripeCardComponent;
  constructor(
    private fb: FormBuilder,
    private authService: AuthService,
    private eventService: EventService,
    private notificationService: NotificationService,
    private translateService: TranslateService,
    private screenWidthService: ScreenWidthService,
    private stripeFactoryService: StripeFactoryService,
  ) {
    this.form = this.fb.group({
      name: ['', [Validators.required]],
      email: ['', [Validators.email, Validators.required]],
      agreeToTerms: [false],
      asGuest: [undefined],
    });

    this.screenWidthService.isDesktop().subscribe({
      next: (isDesktop: boolean) => {
        this.isDesktop = isDesktop;
      },
    });
    this.authService.userSubject.subscribe((user) => {
      if (user.id && this.loggedUser?.id !== user.id) {
        this.loggedUser = user;

        this.form.controls['name'].setValue(this.loggedUser.name);
        if (this.loggedUser.email !== '') {
          this.form.controls['email'].setValue(this.loggedUser.email);
        }
      }

      this.billingAddressForm = this.fb.group({
        type: [
          this.loggedUser?.billingAddress?.type || BillingAddressType.PERSONAL,
          this.isGeneric ? [] : [Validators.required],
        ],
        invoiceRecipient: [
          this.loggedUser?.billingAddress?.invoiceRecipient || '',
          this.isGeneric ||
          !this.loggedUser?.billingAddress ||
          this.loggedUser.billingAddress?.type === BillingAddressType.PERSONAL
            ? []
            : [Validators.required],
        ],
        companyName: [
          this.loggedUser?.billingAddress?.companyName || '',
          this.isGeneric ||
          this.loggedUser?.billingAddress?.type !== BillingAddressType.COMPANY
            ? []
            : [Validators.required],
        ],
        vatNumber: [
          this.loggedUser?.billingAddress?.vatNumber || '',
          this.isGeneric ||
          this.loggedUser?.billingAddress?.type !== BillingAddressType.COMPANY
            ? []
            : [Validators.required],
        ],
        address: [
          this.loggedUser?.billingAddress?.street || '',
          this.isGeneric ? [] : [Validators.required],
        ],
        addressAddition: [this.loggedUser?.billingAddress?.streetNumber || ''],
        locality: [
          this.loggedUser?.billingAddress?.locality || '',
          this.isGeneric ? [] : [Validators.required],
        ],
        federalState: [this.loggedUser?.billingAddress?.federalState || ''],
        postalCode: [
          this.loggedUser?.billingAddress?.postalCode || '',
          this.isGeneric ? [] : [Validators.required],
        ],
        country: [
          this.loggedUser?.billingAddress?.country || '',
          this.isGeneric ? [] : [Validators.required],
        ],
      });

      if (this.isGeneric) {
        this.removeAllValidators();
      }
    });
  }
  removeAllValidators(): void {
    if (this.billingAddressForm) {
      Object.keys(this.billingAddressForm?.controls).forEach((key) => {
        const control = this.billingAddressForm?.get(key);
        control?.clearValidators();
        control?.updateValueAndValidity();
      });
    }
  }

  ngOnInit(): void {
    this.setAppearance();
  }

  setAppearance() {
    if (this.elementsOptions.appearance && this.event) {
      if (this.event.type === EventType.ADVANCED_ONE_COLUMN) {
        this.elementsOptions.appearance.variables = {
          fontFamily: 'Montserrat',
          fontSizeBase: '1rem',
          colorPrimary: '#e60213',
          colorText: '#c5a66d',
          colorBackground: this.event?.eventTemplate?.visual?.backgroundColor,
          borderRadius: '0',
        };
      } else {
        this.elementsOptions.appearance.variables = {
          fontFamily:
            this.event.eventTemplate?.font?.bodyFont + ',  sans-serif',
          fontSizeBase: '1rem',
          colorPrimary: this.event.eventTemplate?.font?.primaryLogoFontColor,
          colorText: this.event.eventTemplate?.font?.bodyFontColor,
          colorBackground: 'white',
          borderRadius: '14px',
        };

        this.elementsOptions.appearance.rules = {
          '.Input': {
            backgroundColor: '#ffffff',
            color: '#333333',
            borderRadius: '14px',
            fontFamily: this.event.eventTemplate?.font?.bodyFont
              ? this.event.eventTemplate?.font?.bodyFont + ',  sans-serif'
              : 'Montserrat',
            fontSize: '14px',
          },
          '.Label': {
            color: '#495057',
            fontFamily: this.event.eventTemplate?.font?.bodyFont
              ? this.event.eventTemplate?.font?.bodyFont + ',  sans-serif'
              : 'Montserrat',
            fontSize: '14px',
          },
          '.TabLabel': {
            fontSize: '14px',
            fontWeight: '500',
          },
        };
      }
    }
  }
  paymentElementOptions: StripePaymentElementOptions = {
    layout: {
      type: 'accordion',
    },
  };

  getAppearance(): MatFormFieldAppearance {
    if (this.event && this.event.type === EventType.ADVANCED_ONE_COLUMN) {
      return 'fill';
    } else return 'outline';
  }

  selectOption(option: string): void {
    this.selectedOption = option;
  }

  stripeElementsChange(event: any) {
    this.isStripeElementValid = event.complete;
    // if (event.complete) {
    //   this.stripe.createPaymentMethod({elements:this.paymentElement.elements})
    //   .subscribe({
    //     next: (res) => {
    //       if (res.paymentMethod) {
    //         this.eventService.
    //         res.paymentMethod.id
    //       }
    //     },
    //     error: () => {

    //     }
    //   })
    // }
  }

  pay() {
    if (this.paying()) return;

    if (!this.form || !this.billingAddressForm || !this.ticketOrder) {
      return;
    }

    if (!this.form.valid) {
      this.form.markAllAsTouched();
      return;
    }

    if (!this.isGeneric) {
      if (!this.billingAddressForm.valid) {
        this.billingAddressForm.markAllAsTouched();
        return;
      }
    }

    this.paying.set(true);

    const billingAddressValue = this.billingAddressForm.value;

    const billingAddress: BillingAddress = {
      type: billingAddressValue.type,

      street: billingAddressValue.address,
      streetNumber: billingAddressValue.addressAddition,
      postalCode: billingAddressValue.postalCode,
      locality: billingAddressValue.locality,

      country: billingAddressValue.country,
      federalState: billingAddressValue.federalState,
    };

    if (billingAddressValue.type === BillingAddressType.PERSONAL) {
      billingAddress.invoiceRecipient = billingAddressValue.invoiceRecipient;
    } else {
      billingAddress.companyName = billingAddressValue.companyName;
      billingAddress.vatNumber = billingAddressValue.vatNumber;
    }

    const req: ConfirmEventTicketOrderRequest = {
      uuid: this.ticketOrder.uuid,
      billingAddress: billingAddress,
    };

    if (this.updatedSessionRequest) {
      req.update = this.updatedSessionRequest;
    }

    const value = this.form.value;

    let name = value.name || '';
    let email = value.email || '';

    if (!this.loggedUser || this.loggedUser.id === 0) {
      req.asGuest = value.asGuest;
      req.email = value.email;

      const guestName = value.name;
      if (guestName && guestName !== '') {
        const nameParts = guestName.trim().split(/\s+/);
        req.firstName = nameParts[0];

        if (nameParts.length > 1) {
          req.lastName = nameParts[1];
        }
      }
    } else {
      name = this.loggedUser.name;
      email = this.loggedUser.email;
    }

    this.eventService.confirmEventTicketOrder(req).subscribe({
      next: (ticketOrder) => {
        if (this.isGeneric) {
          this.stripe
            .confirmCardPayment(ticketOrder.stripePaymentIntentSecret, {
              payment_method: {
                card: this.cardElement.element,
                billing_details: {
                  name: name as string,
                  email: email as string,
                  address: {
                    line1: (billingAddress.street as string) || '',
                    postal_code: (billingAddress.postalCode as string) || '',
                    city: (billingAddress.locality as string) || '',
                  },
                },
              },
              // return_url: 'http://localhost:4200/event/payment/complete',
            })
            .subscribe({
              next: (result) => {
                this.paying.set(false);
                if (result.error) {
                  this.notificationService.error(
                    result.error.message ||
                      this.translateService.instant(
                        'APP.EVENT_PAYMENT.PAYMENT_FAILED',
                      ),
                  );
                } else {
                  if (result.paymentIntent.status === 'succeeded') {
                    this.nextStep.emit();
                  }
                }
              },
              error: (err) => {
                this.paying.set(false);
                this.notificationService.error(
                  this.translateService.instant(
                    'APP.EVENT_PAYMENT.PAYMENT_FAILED',
                  ),
                );
              },
            });
        } else {
          this.stripe
            .confirmPayment({
              elements: this.paymentElement
                ? this.paymentElement.elements
                : this.cardElement.elements,
              confirmParams: {
                payment_method_data: {
                  billing_details: {
                    name: name as string,
                    email: email as string,
                    address: {
                      line1: (billingAddress.street as string) || '',
                      postal_code: (billingAddress.postalCode as string) || '',
                      city: (billingAddress.locality as string) || '',
                    },
                  },
                },
                // return_url: 'http://localhost:4200/event/payment/complete',
              },
              redirect: 'if_required',
            })
            .subscribe({
              next: (result) => {
                this.paying.set(false);
                if (result.error) {
                  this.notificationService.error(
                    result.error.message ||
                      this.translateService.instant(
                        'APP.EVENT_PAYMENT.PAYMENT_FAILED',
                      ),
                  );
                } else {
                  if (result.paymentIntent.status === 'succeeded') {
                    this.nextStep.emit();
                  }
                }
              },
              error: (err) => {
                this.paying.set(false);
                this.notificationService.error(
                  this.translateService.instant(
                    'APP.EVENT_PAYMENT.PAYMENT_FAILED',
                  ),
                );
              },
            });
        }
      },
      error: () => {
        this.paying.set(false);
        this.notificationService.error(
          this.translateService.instant(
            'APP.EVENT_PAYMENT.COULD_NOT_CONFIRM_ORDER',
          ),
        );
      },
    });
  }

  protected readonly NumberUtils = NumberUtils;
  protected readonly EventType = EventType;
}
