import { UpperCasePipe } from '@angular/common';
import {
  AfterViewChecked,
  Component,
  CUSTOM_ELEMENTS_SCHEMA,
  ElementRef,
  EventEmitter,
  Input,
  NgZone,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  MatFormField,
  MatFormFieldAppearance,
  MatLabel,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { BillingAddressType } from '@models/payments/billing-address-type.enum';
import { CredentialsFormButtonToggleComponent } from '@modules/shared/components/credentials-form-button-toggle/credentials-form-button-toggle.component';
import { TelInputComponent } from '@modules/shared/components/tel-input/tel-input.component';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-tax-invoice-details',
  standalone: true,
  imports: [
    CredentialsFormButtonToggleComponent,
    FormsModule,
    MatFormField,
    MatInput,
    TranslateModule,
    UpperCasePipe,
    ReactiveFormsModule,
    MatLabel,
    TelInputComponent,
  ],
  templateUrl: './tax-invoice-details.component.html',
  styleUrl: './tax-invoice-details.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class TaxInvoiceDetailsComponent implements AfterViewChecked {
  @Input() form?: FormGroup;
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() showTitle: boolean = true;
  @Input() isGeneric: boolean = false;
  place?: google.maps.places.PlaceResult;
  @ViewChild('addressInput') addressInput!: ElementRef;
  isAutocompleteInitialized = false;
  @Output() setPlace = new EventEmitter<google.maps.places.PlaceResult>();
  constructor(private ngZone: NgZone) {}

  ngAfterViewChecked(): void {
    if (!this.isAutocompleteInitialized && this.addressInput) {
      this.initializeGooglePlacesAutocomplete();
      this.isAutocompleteInitialized = true;
    }
  }
  initializeGooglePlacesAutocomplete(): void {
    if (this.addressInput && this.form) {
      const input = this.addressInput.nativeElement;
      const autocomplete = new google.maps.places.Autocomplete(input, {
        types: ['geocode'],
      });

      autocomplete.addListener('place_changed', () => {
        const place = autocomplete.getPlace();
        if (place && place.formatted_address) {
          this.ngZone.run(() => {
            if (this.form) {
              this.form.controls['address'].setValue(place.formatted_address);
              this.place = place;
              this.setPlace.emit(place);
              this.form?.controls['address'].setValue(
                this.getStreet(this.place),
              );
              this.form?.controls['addressAddition'].setValue(
                this.getStreetNumber(this.place),
              );
              this.form?.controls['postalCode'].setValue(
                this.getPostalCode(this.place),
              );
              this.form?.controls['country'].setValue(
                this.getCountry(this.place),
              );
              this.form?.controls['locality'].setValue(
                this.getLocality(this.place),
              );
            }
          });
        }
      });
    }
  }

  private getStreetNumber(place: google.maps.places.PlaceResult): string {
    const component = place.address_components?.find((c) =>
      c.types.includes('street_number'),
    );
    return component ? component.long_name : '';
  }

  private getCountry(place: google.maps.places.PlaceResult): string {
    const component = place.address_components?.find((c) =>
      c.types.includes('country'),
    );
    return component ? component.long_name : '';
  }

  private getPostalCode(place: google.maps.places.PlaceResult): string {
    const component = place.address_components?.find((c) =>
      c.types.includes('postal_code'),
    );
    return component ? component.long_name : '';
  }

  private getLocality(place: google.maps.places.PlaceResult): string {
    const component = place.address_components?.find((c) =>
      c.types.includes('locality'),
    );
    return component ? component.long_name : '';
  }

  private getStreet(place: google.maps.places.PlaceResult): string {
    const component = place.address_components?.find((c) =>
      c.types.includes('route'),
    );
    return component ? component.long_name : '';
  }
  setIsCompany(value: boolean) {
    this.form?.controls['type'].setValue(
      value ? BillingAddressType.COMPANY : BillingAddressType.PERSONAL,
    );

    if (!this.isGeneric) {
      this.form?.controls['type'].valueChanges.subscribe((val) => {
        if (val === BillingAddressType.PERSONAL) {
          this.form?.controls['companyName'].removeValidators(
            Validators.required,
          );
          this.form?.controls['vatNumber'].removeValidators(
            Validators.required,
          );

          this.form?.controls['invoiceRecipient'].setValidators(
            Validators.required,
          );
        } else {
          this.form?.controls['invoiceRecipient'].removeValidators(
            Validators.required,
          );

          this.form?.controls['companyName'].setValidators(Validators.required);
          this.form?.controls['vatNumber'].setValidators(Validators.required);
        }

        this.form?.controls['companyName'].updateValueAndValidity();
        this.form?.controls['vatNumber'].updateValueAndValidity();
        this.form?.controls['invoiceRecipient'].updateValueAndValidity();
      });
    }
  }

  getAsteriskIfRequired(controlName: string): string {
    const control = this.form?.get(controlName);
    if (control && control.validator) {
      const validator = control.validator({} as AbstractControl);
      if (validator && validator['required']) {
        return '*';
      }
    }
    return '';
  }

  protected readonly BillingAddressType = BillingAddressType;
}
