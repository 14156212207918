@if (form) {
  <form class="form" [formGroup]="form" (keyup.enter)="next()">
    <app-credentials-form-button-toggle
      [selectedOption]="selectedOption"
      (selectedOptionEvent)="selectOption($event)"
    >
    </app-credentials-form-button-toggle>

    @if (selectedOption === "email") {
      <ng-container formGroupName="emailFormGroup">
        <mat-form-field
          class="w-100 email-input"
          [class.has-value]="
            emailFormGroup.controls['email'].value &&
            emailFormGroup.controls['email'].value !== ''
          "
          appearance="outline"
        >
          <mat-label>{{ "APP.AUTH.EMAIL_ADDRESS" | translate }}</mat-label>
          <input
            type="email"
            matInput
            formControlName="email"
            placeholder=""
            autocomplete="username"
          />
          @if (showCoHostEmailChangedHint) {
            <mat-hint class="co-host-changed-hint">
              <div
                [innerHTML]="
                  'APP.CO_HOST_INVITATION.INVITATION_EMAIL_CHANGED_HINT'
                    | translate: { email: invitedCoHostEmail }
                "
              ></div>
            </mat-hint>
          }
          @if (
            globalSettings?.registerWithoutEmail &&
            emailFormGroup.controls["email"].hasError("custom")
          ) {
            <mat-error>
              {{ emailFormGroup.controls["email"].getError("custom") }}
            </mat-error>
          }
          @if (
            emailFormGroup.controls["email"].hasError("email") &&
            !emailFormGroup.controls["email"].hasError("required")
          ) {
            <mat-error>{{
              "APP.AUTH.ERRORS.EMAIL_INVALID" | translate
            }}</mat-error>
          } @else if (emailFormGroup.controls["email"].hasError("required")) {
            <mat-error>
              {{ "APP.AUTH.ERRORS.EMAIL_REQUIRED" | translate }}
            </mat-error>
          }
        </mat-form-field>
      </ng-container>
    }

    <ng-container formGroupName="phoneFormGroup">
      <mat-form-field
        class="w-100 phone-input"
        [class.display-none]="selectedOption === 'email'"
        [class.has-value]="
          phoneFormGroup.controls['phone'].value &&
          phoneFormGroup.controls['phone'].value !== ''
        "
        appearance="outline"
      >
        <app-tel-input #telInput formControlName="phone"></app-tel-input>
        @if (phoneFormGroup.controls["phone"].hasError("custom")) {
          <mat-error>
            {{ phoneFormGroup.controls["phone"].getError("custom") }}
          </mat-error>
        } @else if (phoneFormGroup.controls["phone"].hasError("required")) {
          <mat-error>
            {{ "APP.AUTH.ERRORS.PHONE_REQUIRED" | translate }}
          </mat-error>
        } @else if (
          phoneFormGroup.controls["phone"].hasError("invalidNumber")
        ) {
          <mat-error>
            {{ "APP.AUTH.ERRORS.PHONE_INVALID" | translate }}
          </mat-error>
        }
      </mat-form-field>
    </ng-container>
    <button
      type="button"
      class="btn btn-primary-party-page"
      (click)="next()"
      [class.spinner]="loginIsInProgress && selectedOption === 'phone'"
      [disabled]="loginIsInProgress"
    >
      {{ (selectedOption === "email" ? "APP.NEXT" : "APP.LOGIN") | translate }}
    </button>
  </form>
}
