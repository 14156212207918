import { UpperCasePipe } from '@angular/common';
import { Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import {
  MatFormField,
  MatFormFieldAppearance,
  MatLabel,
} from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { User } from '@models/users/user.model';
import { AuthDialog } from '@modules/auth/dialogs/auth/auth.dialog';
import { CredentialsFormButtonToggleComponent } from '@modules/shared/components/credentials-form-button-toggle/credentials-form-button-toggle.component';
import { TelInputComponent } from '@modules/shared/components/tel-input/tel-input.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuthService } from '@services/auth/auth.service';
import { CamelCasePipe } from '../../../../../../pipes/camel-case.pipe';

@Component({
  selector: 'app-personal-data',
  standalone: true,
  imports: [
    CredentialsFormButtonToggleComponent,
    UpperCasePipe,
    ReactiveFormsModule,
    MatLabel,
    MatFormField,
    TranslateModule,
    MatInput,
    TelInputComponent,
    CamelCasePipe,
  ],
  templateUrl: './personal-data.component.html',
  styleUrl: './personal-data.component.scss',
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PersonalDataComponent {
  selectedOption: string = 'email';
  @Input() form?: FormGroup;
  @Input() loggedUser?: User;
  @Input() appearance: MatFormFieldAppearance = 'fill';
  @Input() showTitle: boolean = true;

  constructor(
    private authService: AuthService,
    private dialog: MatDialog,
  ) {}

  selectOption(option: string): void {
    this.selectedOption = option;
  }

  login() {
    const authDialog = this.dialog.open(AuthDialog, {
      width: '100%',
      height: 'auto',
      maxWidth: '600px',
      panelClass: ['normal-dialog'],
      data: { isLogin: true, isTicketing: true, showBackButton: false },
    });

    authDialog.afterClosed().subscribe((res) => {
      if (res && res.success) {
        this.authService.refreshTheLoggedUserData();
      }
    });
  }
}
